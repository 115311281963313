import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import 'vant/lib/index.less'
import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import setRem from './util/rem'
setRem();
Locale.use('en-US', enUS);
Vue.config.productionTip = false;

 Vue.use(vant);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
