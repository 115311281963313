<template>
  <div class="home">
      <div class="headerPart" >
        <div class="info">
            Youtube online work

        </div>
        <div class="swipeWrap">
          <div style="display:block" class="swipeWrap1" :style="{width:screeWidth+'px'}">
           <van-swipe class="my-swipe" style="height:5.24rem;" :autoplay="3000" indicator-color="white">
            
            <van-swipe-item v-for="(item,i) in bannerList" :key="i" >
               
               <img :src="item" style="width:100%;"/>
            </van-swipe-item>
             
          </van-swipe> 
          </div>
        </div>
      </div>
      <div class="invite">
       <a @click="copyText">
         <img  src="../assets/img/home_banner.png"/>
       </a>

      </div>

      <div class="crossing">
         <div class="item">
           <div class="item_detail">
             <div class="item_detail_wrap item_detail_wrap_odd" @click="goRecharge">
               <div>Recharge</div>
               <img src="../assets/img/ic_Recharge.png"/>
             </div>
           </div>
           <div class="item_detail">
             <div class="item_detail_wrap item_detail_wrap_even" @click="gowithdraw">
               <div>Withdrawal</div>
               <img src="../assets/img/ic_withdrawal.png"/>
             </div>
           </div>
           <div class="item_detail">
             <div class="item_detail_wrap item_detail_wrap_odd" @click="goNotice">
               <div>Tutorial</div>
               <img src="../assets/img/ic_tutorial.png"/>
             </div>
           </div>
           <div class="item_detail">
             <div class="item_detail_wrap item_detail_wrap_even" @click="goTeam">
               <div>Team size</div>
               <img src="../assets/img/ic_team.png"/>
             </div>
           </div>
         </div>
         
        
      </div>
      <div class="main_crossing">
        <div class="title">
            <div>MISSION HALL</div> 
        </div>
        <div class="item">
           <div class="item_detail missionwrap">
             <div class="item_detail_wrap item_detail_wrap_odd " @click="goTaskHall">
               <div class="mission">
                 <div>TASK</div>
                 <div>HALL</div>
               </div>
               <img src="../assets/img/ic_task.png"/>
             </div>
           </div>
           <div class="item_detail missionwrap">
             <div class="item_detail_wrap item_detail_wrap_even" @click="goBusinessHall">
               <div class="mission">
                 <div>BUSINESS</div>
                 <div>HALL</div>
               </div>
               <img src="../assets/img/group1.png"/>
             </div>
           </div>
           
         </div>
        
      </div>
      <div class="messgeshow">
         <div class="tab"> 
              <div class="tabtitle">
                 <div :class="{tbactive:tactive==1?true:false}" @click="changeTb(1)">MemberShip</div>
                  <div :class="{tbactive:tactive==2?true:false}" @click="changeTb(2)">Merchant</div>
              </div>
              <div class="tabcontent">
                  <div v-if="tactive==1" class="tbcontent">
                   <van-swipe  @change="changeIndex" ref="swipeList1" id="swipeList1" style="height:340px;" :autoplay="2000"  vertical :loop="true" :show-indicators="false" height="60">
                    <van-swipe-item v-for="item in memberList" :key="item.username"> 
                      <div class="tbItem"  >
                         <div class="user_img">
                           <img :src="item.header"/>
                         </div>
                         <div class="user_info">
                             <div class="user_name">   
                                 <div>Congratulations {{item.username}}</div>
                                 <div style="display:none">10s ago</div>
                             </div>
                             <div class="user_msg">
                                   Complete {{item.number}} single task today and earn KSH{{item.profit}}!

                             </div>
                         </div>
                      </div>

                    </van-swipe-item>
                               
                  </van-swipe>
                  </div>
                  <div v-if="tactive==2" class="tbcontent">
                       <van-swipe  :autoplay="2000"   @change="changeIndex2" ref="swipeList2" id="swipeList2" style="height:340px;"  vertical :loop="true" :show-indicators="false" height="60">
                    <van-swipe-item v-for="item in businessList" :key="item.username">
                      <div class="tbItem">
                         <div class="user_img">
                           <img :src="item.header"/>
                         </div>
                         <div class="user_info">
                             <div class="user_name">   
                                 <div>{{item.username}}</div>
                                 
                             </div>
                             <div class="user_msg">
                                   Release {{item.number}} single task today

                             </div>
                         </div>
                         <div class="task_info">
                            <div >{{item.profit}}</div>
                            <div>REWARD</div>
                         </div>
                      </div>

                    </van-swipe-item>

                    
                       </van-swipe>
                  </div>
              </div>
         </div>
        
      </div>
      <div style="display:none" id="swipeTemplate">
        <div v-for="(item,index) in memberList" :key="index" class="van-swipe-item" style="height: 60px;">
                <div class="tbItem"  >
                         <div class="user_img"> 
                           <img :src="item.header"/>
                         </div>
                         <div class="user_info">
                             <div class="user_name">   
                                 <div>Congratulations {{item.username}}</div>
                                 <div>10s ago</div>
                             </div>
                             <div class="user_msg">
                                   Complete {{item.number}} single task today and earn KSH{{item.profit}}!

                             </div>
                         </div>
                  </div>
        </div>

      </div>
     <div style="display:none" id="swipe2Template">
        <div v-for="(item,index) in businessList" :key="index" class="van-swipe-item" style="height: 60px;">
                   <div class="tbItem">
                         <div class="user_img">
                           <img :src="item.header"/>
                         </div>
                         <div class="user_info">
                             <div class="user_name">   
                                 <div>{{item.username}}</div>
                                 
                             </div>
                             <div class="user_msg">
                                   Release {{item.number}} single task today

                             </div>
                         </div>
                         <div class="task_info">
                            <div >{{item.profit}}</div>
                            <div>REWARD</div>
                         </div>
                      </div>
        </div>

      </div>
  </div>
</template>

<script>
 import {fetch} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
  name: "Home",
  data(){
     return{
       screeWidth:document.body.clientWidth-30,
       tactive:1,
       bannerList:[],
       basepath:process.env.VUE_APP_BASE_API,
       memberList:[],
       merchant:[],
       businessList:[],
       swipeInit:false,
       swipe2Init:false
     }
  },
   created(){
    this.screeWidth=document.body.clientWidth-30;
     this.$store.dispatch("setShowNav",true);
     this.init();
     if(this.$route.query.index){
        this.$store.dispatch("setMenuActive",parseInt(this.$route.query.index));
     }
  },
  methods:{
    copyText:function(){
      // var text=this.$store.state.config.info.setting.writing;
      //           var input = document.createElement("textarea");   // 直接构建input
      //           input.value = text;  // 设置内容
      //           document.body.appendChild(input);    // 添加临时实例
      //           input.select();   // 选择实例内容
      //           document.execCommand("Copy");   // 执行复制
      //           document.body.removeChild(input); // 删除临时实例
      //           this.$toast(" copy success");
       this.$router.push({
                  path: "/invite" 
            });
    },
    goNotice:function(){
    this.$router.push({
                  path: "/notice" 
            });
    },
    gowithdraw:function(){
this.$router.push({
              path: "/withdraw" 
        });
    },
    goTeam:function(){

        this.$router.push({
              path: "/team" 
        });
    },
    goRecharge:function(){
        this.$router.push({
              path: "/recharge" 
        });
    },
    changeIndex:function(index){
      console.log("index111111");
       if(!this.swipeInit){
          this.swipeInit=true;
              var swipeWrap=document.querySelector("#swipeList1>.van-swipe__track--vertical");
              var swipeTemplate=document.querySelector("#swipeTemplate");
              for(var i=0;i<10;i++){
                var temp=swipeTemplate.children[i];
                console.log(temp);
               // temp.id="asd"+i;
                swipeWrap.appendChild(temp);
              }
       }
    },
    changeIndex2:function(index){
      console.log("index2222");
       if(!this.swipe2Init){
          this.swipe2Init=true;
              var swipeWrap=document.querySelector("#swipeList2>.van-swipe__track--vertical");
              var swipeTemplate=document.querySelector("#swipe2Template");
              for(var i=0;i<10;i++){
                var temp=swipeTemplate.children[i];
                console.log(temp);
               // temp.id="asd"+i;
                swipeWrap.appendChild(temp);
              }
       }
    },
    goBusinessHall:function(){
      this.$router.push({
              path: "/businesshall" 
        });
    },
    goTaskHall:function(){
      this.$router.push({
              path: "/taskhall",
              query:{
                menu:1
              } 
        });
    },
    changeTb:function(index){
       this.tactive=index;
    },
    init:async function(){
       this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner',
            overlay:true
            });
      var res= await getBackData();
       
       this.bannerList=res.info.bannerList;
       this.memberList=res.info.memberList
       this.businessList=res.info.businessList;
      this.$toast.clear();
      await getUserInfo();
    }
  },
  activated(){
 this.$store.dispatch("setShowNav",true);
  },
  mounted(){
    console.log(this.$refs.swipeList1);
    console.log(document.querySelector("#swipeList1>.van-swipe__track--vertical"));

  } 

};
</script>
<style>
.home .van-swipe-item{
  background: #ffffff;
} 
.home .van-swipe-item img{
  border-radius: 10px;
}

</style>
<style scoped>
.mission{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  
}
.mission>div{
  padding-left:10px;
}
.invite img{
  width:100%;
}
.task_info{
  display:flex;
  flex-direction: column;
  align-items: stretch;
  justify-content:space-around;
}
.task_info>div:nth-child(1){
  color:#F9034F;
 font-weight: 600;
  font-size:14px;

}
.task_info>div:nth-child(2){
  color:#F9034F;
   font-size:12px;
 font-weight: 400;
 color: #999999;

}
.tbcontent{
  padding:20px 0px;
}
.tbItem{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: stretch;
  background: #ffffff;
}
.tbItem .user_info{
  display: flex;
  flex-direction: column;
  margin-left:10px;
  justify-content:space-around;
  flex-grow:1;
}
.tbItem .user_name{
  display:flex;
  flex-direction: row;
 justify-content: space-between;
 font-size:0.35rem;
}
.tbItem .user_msg{
  display:flex;
  justify-content: flex-start;
  font-size:0.32rem;
}
.tbItem .user_name>div{
  
  display: flex;
  align-items: flex-start;
  
}
.tbactive{
  color:#000000 !important;
  border-bottom:3px solid red !important;
}
.messgeshow{
  margin:10px 14px;
}
.tabtitle{
  display:flex;
  flex-direction: row;
}
.tabtitle>div{
  font-size:15px;
  font-weight: bold;
  margin:0px 20px 0px  0px;
  color:#cccccc;
  border-bottom:3px solid #ffffff;
  
}
.title{
 display:flex;
}
.title>div{
  width:105px;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left:3px solid red;
  font-weight: bold;
  font-size:13px;
}
.user_img >img{
  width:46px;
  border-radius: 30px;
}
.item{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:10px 14px 10px; 
  
}
.item>div{
  
  height:2rem;
  flex-basis: 50%;
}
.item_detail{
  display:flex;
  flex-direction: row; 
  justify-content: space-between;
 
  border-radius: 4px;
  color:#ffffff;
  margin-bottom:10px;
}
.item_detail_wrap_odd{
  margin-left:0px;
}
.item_detail_wrap_even{
    margin-left:10px;
}
.invite{
  margin:0.45rem 14px 0.22px; 
  border-radius:10px;
}
.missionwrap img{
  margin-right:10px;
}
.info{
  color:#FFFFFF;
  font-size:0.45rem;
}
</style>
<style lang="less">
.home .van-tab{
  flex:0;
}
.home{
  display: -webkit-flex;
  flex-direction: column;
  overflow-x: hidden;
}
.headerPart{
  height:6.7rem;
  width:100%;
  background: url("../assets/img/bk_home_top.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  justify-content: flex-end;
  flex-direction: column;
}
 .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
  }
   .swipeWrap1{
     border-radius: 10px;
     overflow: hidden;
     width:100%;
   }
    
.swipeWrap{
  overflow:hidden;
   display: flex;
   justify-content: center;
}
.info{
  height:1.5rem;
  display:flex;
  align-items: center;
  justify-content: center;
}


.item_detail_wrap{
   display:flex;
  flex-direction: row; 
  justify-content: space-between;
  background: #131b2f;
  border-radius: 4px;
  color:#ffffff;
  flex-grow: 1;
  align-items: center;
  

}
.item_detail_wrap>div{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:0.37rem;
}
.item_detail_wrap>img{
  width:1.38rem;
  height:1.38rem;
}
.tabcontent{
  background: #ffffff;
}
</style>
