import request from '@/util/request'
import store from '@/store'
export function post(url, data) {
   if(!data.lang){
    data.lang="en";
   }
    return request({
        url: url,
        method: 'post',
        data
    })
}
export function get(url, param) {
    return request({
        url: url,
        method: 'get',
        params: { param }
    })
}
export function fetch(url, params) {
    let queryString = []
    Object.keys(params).forEach(key => params[key] != undefined && queryString.push(`${key}=${params[key]}`))
    if (queryString.length > 0) {
      queryString = queryString.join('&')
      url += `?${queryString}`
    }
    return request({
      url: url,
      method: 'post'
    })
  }
export  function getBackData(){
  return new Promise(async (resolve,reject)=>{
    try{

       console.log(store.getters.config);
       if(!store.getters.config){
          fetch("api/Common/BackData",{lang:'en'}).then(res=>{
            console.log("获取数据",res);
            store.dispatch('setConfig',res);
            resolve(res)
          })
       }else{
        resolve(store.getters.config)
       }

    }catch(e){
       reject("err");
    }

  })
 
}  

export  function getUserInfo(refresh){
  
  return new Promise(async (resolve,reject)=>{
    try{

       console.log("获取用户信息");
       if(!store.getters.userInfo||refresh==1){
         if(store.getters.token){
          post("api/user/getUserInfo",{}).then(res=>{
            console.log("获取用户信息",res);
            if(res.code==1){
              store.dispatch('setUserInfo',res.info);
              resolve(res.info)
            }else{
              resolve(null)
            }
           
          }).catch(err=>{
            resolve(null)
          })
         }else{
          resolve(null)
         }

       }else{
        resolve(store.getters.userInfo)
       }

    }catch(e){
       reject("err");
    }

  })
 
}  