import axios from 'axios' 
import store from '@/store'
 import { getToken } from '@/util/auth'
 import { Toast } from 'vant';
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 12 * 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Mx-ReqToken'] = getToken()
    }
   // config.headers['Content-Type']='application/x-www-form-urlencoded';
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // res.code 不为 0 的时候，说明有异常
    // if (res.code !==1 &&res.code !==203&&res.code!==204&&res.code!==0) {
    //   console.log("请求异常");
    //   Toast.fail(res.code_dec);
    //   // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了；1000:Token 过期了
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 1000) {
    //     console.log("已经被登出");
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
    return res;
  },
  error => {
    console.log('err' + error) // for debug
    console.log("error");
    return Promise.reject(error)
  }
)

export default service
