<template>
    <div class="dialog_wrap" v-if="show">
          <div class="wrap">
            <div class="dialogwrap">
                <div style="text-align:center">{{title}}</div>
                 <div class="dialogcontent" v-html="msg"> 
                 </div>
                 <div>
                    
                     <div v-if="type=='prompt'" class="prompt">
                        <div @click="doclose">Determine</div> 
                     </div>
                     <div v-else>
                        <div @click="doOk">Determine</div>
                        <div @click="cancel">Cancel</div>
                     </div>
                 </div>
            </div>
          </div>

    </div>
</template>
<style>
.prompt{
    justify-content:center !important;
}
.dialogcontent p{
    padding-top:0px;
    margin-top:0px;
}
</style>
<style scoped>
.dialogcontent{
    word-break:break-all;
}

.dialogwrap>div{
    flex-grow: 1;
}
.dialogwrap>div:nth-child(1){
    font-size:18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
     flex-grow: 0;
     height:30px;
}
.dialogwrap>div:nth-child(2){
  
    color:#333333;
    display: flex;
    padding:10px 15px 0px;
    align-items: flex-start;
    justify-content: center;
    line-height:0.45rem;
    font-size:0.4rem;
    
     
}
.dialogwrap>div:nth-child(3){
    height:35px;
    font-size:14px;
    color:#333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    width:100%; 
    
     
}
.dialogwrap>div:nth-child(3)>div{
     margin:0px 20px;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     flex-grow: 1;
     color:#ffffff;
}
.dialogwrap>div:nth-child(3)>div>div{
    border-radius: 5px;
    width:90px;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.dialogwrap>div:nth-child(3)>div>div:nth-child(1){
    background: #F80050;
    padding:10px 0px;
}
.dialogwrap>div:nth-child(3)>div>div:nth-child(2){
    background: #CFCFCF;
    padding:10px 0px;
}
.dialogwrap{
     min-height:199px;
    width:266px;
    background:#ffffff;
    border-radius: 10px; 
    padding:20px 15px 20px;
    display:flex; 
    flex-direction: column;
}
.dialogwrap>div{
    flex-grow: 1;
}
.wrap{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}
.dialog_wrap{
     width:100%;
    position: fixed;
    z-index: 11111;
    top:0px;
    right:0px;
    left:0px;
    bottom:0px;
    background: rgba(0,0,0,0.4);
}
</style>
<script>
export default { 
    props: ['vtitle','vshow','vmsg','vtype'],
    data(){
        return{
           show:this.vshow?this.vshow:false,
           title:this.vtitle?this.vtitle:'prompt',
           msg:this.vmsg?this.vmsg:'',
           type:this.vtype
        }
    },
    methods:{
      doOk:function(){
          this.$emit("ok");
      },
      cancel:function(){
           this.$emit("cancel");
      },
      doclose:function(){
           this.$emit("close"); 
      }
    },
    watch:{
         vshow(v,ov){
             console.log("vshow 发生变更了");
             this.show=v;
         },
         vtitle(v,ov){
            this.title=v; 
         },
         vmsg(v,ov){
             this.msg=v;
         }
    }
}
</script>

