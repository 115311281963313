
<template>
  <div id="app">
    <div class="content">
       <keep-alive>
      <router-view />
       </keep-alive>
    </div>
    <div class="nav" v-if="this.$store.state.showNav">
      <van-tabbar v-model="active" @change="onChange">
       
        <van-tabbar-item >
          <span>Home</span>
          <template #icon="props">
            <img :src="props.active ? homeImg2 : homeImg1" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item >
          <span>Task</span>
          <template #icon="props">
          
            <img :src="props.active ? taskImg2 : taskImg1" />
          </template>
        </van-tabbar-item>

        <van-tabbar-item >
          <span>Vip</span>
          <template #icon="props">
            <img :src="props.active ? vipImg2 : vipImg1" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item >
          <span>Profit</span>
          <template #icon="props">
            <img :src="props.active ? profitImg2 : profitImg1" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item >
          <span>My</span>
          <template #icon="props">
            <img :src="props.active ? myImg2 : myImg1" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div> 
    <div class="contactMe">
      <img @click="openService" src="@/assets/img/ic_service.png"/>
    </div>

     <f-dialog :vshow="showpwd" vtype="prompt" :vtitle="title" :vmsg="msg" @ok="dodraw" @close="dodraw" @cancel="cancel"></f-dialog>
  </div>
</template>
<style>
.contactMe{
  position:fixed;
  bottom:1.4rem;
  right:0.2rem;
}
</style>
<script>
import fdialog from './views/dialog'  
import {getBackData,getUserInfo,fetch} from '@/util/api'
import { mapGetters } from 'vuex'
export default {
  components:{fDialog:fdialog},
  data(){
    return{
      showpwd:false,
      title:'',
      flag:'-1',
      msg:'',
      url:'',
       showNav:this.$store.state.showNav,
       homeImg1:require("./assets/img/ic_home_1.png"),
       homeImg2:require("./assets/img/ic_home_2.png"),
       taskImg1:require("./assets/img/ic_task_1.png"),
       taskImg2:require("./assets/img/ic_task_2.png"),
       vipImg1:require("./assets/img/ic_vip_1.png"),
       vipImg2:require("./assets/img/ic_vip_2.png"),
       profitImg1:require("./assets/img/ic_profit_1.png"),
       profitImg2:require("./assets/img/ic_profit_2.png"),
       myImg1:require("./assets/img/ic_my_1.png"),
       myImg2:require("./assets/img/ic_my_2.png"),
       screeWidth:0
    }
  },
  computed: {
     active:{
       get(){
         console.log("获取菜单变更信息");
          return this.$store.state.menuActive;
       },
       set(newValue){
        this.$store.dispatch("setMenuActive",newValue);
       }
     }
  },
  methods:{
    dodraw:function(){
       this.showpwd=false;
       setTimeout(()=>{
          window.location.href=this.url;
       },500)
    },
    cancel:function(){
       this.showpwd=false;
    },
    openService:function(){
         var config=this.$store.state.config;
         var kf=config.info.kf;
         if(kf){
           window.open(kf.linkname,"_blank")
         }else{
           this.$toast("")
         }
    },
    onChange(index) {
      
      if(index==0){
        this.$router.push({
              path: "/",
              query:{index:index}  
        });
      }
      if(index==1){
        this.$router.push({
              path: "/task",
              query:{index:index} 
        });
      }
      if(index==2){
        this.$router.push({
              path: "/vip",
              query:{index:index}  
        });
      }
      if(index==3){
        this.$router.push({
              path: "/profit",
              query:{index:index}  
        });
      }
     if(index==4){
        this.$router.push({
              path: "/my",
              query:{index:index}  
        });
      }

    },
    init:async function(){
     var backData= await getBackData();
     console.log("backData",backData);
     
     try{
        if(backData.info.noticelist.length>0){
          this.showpwd=true;
          this.title=backData.info.noticelist[0].title;
          this.msg=backData.info.noticelist[0].content;
          this.url=backData.info.noticelist[0].url;
          if(this.url){
            if(this.url.indexOf("show")<0){
              if(this.url.indexOf("?")>0){
                this.url=this.url+"&show=0"
              }else{
                 this.url=this.url+"?show=0"
              }
            }
          }
        }
     }catch(e){

     }
    }
  },
  created(){
    console.log("初始化");
   

  },
  mounted(){
    console.log( this.active);
    var istest=0;
    if(window.location.href.indexOf("test")>0){
      this.flag=1
    }
  this.flag=1
    console.log("flag",this.flag);
    if(this.flag==1){
 this.init();
    }
    
  }
};
</script>
<style lang="less">
html,body{
  font-size:62.5%;
  height:100%;
  overflow: hidden;
  width:100%;
  max-width:100%;
}
#app {
  height:100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;
  display: flex;
  display: -webkit-flex;
  flex-direction:column;
  justify-content:flex-end;
}
.van-nav-bar .van-icon{
  color:#333333 !important;
}
.nav{ 
}
.content{
  flex-grow: 1;
  display: flex;
  display: -webkit-flex;
  overflow-y: auto;
}
.content>div{
  flex-grow: 1;
}
</style>
